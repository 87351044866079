<template>
  <b-container class="my-4">
    <b-row>
      <b-col cols="12" sm="9" class="mb-3">
        <h3 class="mb-4">{{ $t('contact_with_us') }}</h3>
        <p>
          {{ $t('contact_desc') }}
        </p>
      </b-col>
      <b-col cols="12" md="4">
        <div class="info mb-3">
          <div class="info__heading">{{ $t('phones') }}:</div>
          <a href="tel:+998712314881" class="info__content">
            +998 71 231-48-81
          </a>
          <a href="tel:+998977542076" class="info__content">
            +998 97 754-20-76
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('fax') }}:</div>
          <a href="tel:+998712314181" class="info__content">
            +998 71 231-41-81
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('email') }}</div>
          <a href="mailto:info@postda.uz" class="info__content">
            info@postda.uz
          </a>
        </div>
        <div class="info mb-3">
          <div class="info__heading">{{ $t('address') }}:</div>
          <div class="info__content">
            {{ $t('address_full') }}
          </div>
        </div>
        <social-links />
      </b-col>
      <b-col cols="12" md="8" class="mt-5 mt-md-0">
        <form
          @submit.prevent="submitContact"
          :class="{ 'form--sent': isSent }"
          :data-txt="$t('sent')"
        >
          <b-row>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                class="input"
                :placeholder="`${$t('name')}*`"
                v-model="form.name"
                required
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="tel"
                class="input"
                v-model="form.phone"
                v-mask="'+998 (##) ###-##-##'"
                :placeholder="`${$t('phone_placeholder')}*`"
                required
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="email"
                v-model="form.email"
                class="input"
                :placeholder="$t('email')"
              />
            </b-col>
            <b-col cols="12" sm="6" class="mb-4">
              <input
                type="text"
                v-model="form.address"
                class="input"
                :placeholder="$t('address_placeholder')"
              />
            </b-col>
            <b-col cols="12">
              <textarea
                :placeholder="`${$t('message')}*`"
                class="input input--textarea"
                required
                v-model="form.message"
              ></textarea>
              <button
                :disabled="isSending"
                class="button button--default button--block mt-4"
                type="submit"
              >
                {{ $t('send') }}
              </button>
            </b-col>
          </b-row>
        </form>
        <i18n path="term" tag="div" class="hint mt-4" for="tos">
          <a
            href="#"
            @click.prevent="$bvModal.show('privacy-modal')"
            class="text-link"
            >{{ $t('tos') }}</a
          >
        </i18n>
      </b-col>
    </b-row>
    <privacy-policy-modal />
  </b-container>
</template>

<script>
import SocialLinks from '@/components/SocialLinks'
import PrivacyPolicyModal from '@/components/PrivacyPolicyModal'

export default {
  components: {
    SocialLinks,
    PrivacyPolicyModal
  },
  data: () => ({
    form: {
      name: '',
      phone: '',
      email: '',
      address: '',
      message: ''
    },
    isSending: false,
    isSent: false
  }),
  metaInfo() {
    return {
      title: this.$t('nav.contact')
    }
  },
  methods: {
    submitContact() {
      if (this.isSending || this.isSent) return
      this.isSending = true
      this.$store
        .dispatch('sendContacts', this.form)
        .then(() => (this.isSent = true))
        .finally(() => (this.isSending = false))
    }
  }
}
</script>
